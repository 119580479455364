import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'

const Kabely = () => {
  return (
    <>
      <h1>Tabulka průřezů kabelů AWG na mm</h1>
      <p>AWG je americká norma, dalo by se říci, že číslo znamená, kolik kabelů se vejde do nějaké základní jednotky. Čím vyšší číslo, tím více se jich tam vejde a tím jsou pochopitelně tenčí.</p>
      <Col>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Označení AWG</th>
              <th>Průměr [mm]</th>
              <th>Průřez [mm<sup>2</sup>]</th>
              <th>Maximální zátěž [A]</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>000000</td>
              <td>14,733</td>
              <td>170,00</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>00000</td>
              <td>13,13</td>
              <td>135,00</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>0000</td>
              <td>11,684</td>
              <td>103,80</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>000</td>
              <td>10,40</td>
              <td>79,00</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>00</td>
              <td>9,27</td>
              <td>67,50</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>0</td>
              <td>8,25</td>
              <td>53,40</td>
              <td>&mdash;</td>
            </tr>
              <tr>
              <td>1 AWG</td>
              <td>7,34</td>
              <td>42,20</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>2 AWG</td>
              <td>6,55</td>
              <td>33,70</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>3 AWG</td>
              <td>5,82</td>
              <td>26,60</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>4 AWG</td>
              <td>5,18</td>
              <td>21,00</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>5 AWG</td>
              <td>4,62</td>
              <td>16,90</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>6 AWG</td>
              <td>4,115</td>
              <td>13,25</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>7 AWG</td>
              <td>3,66</td>
              <td>10,25</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>8 AWG</td>
              <td>3,26</td>
              <td>8,34</td>
              <td>180</td>
            </tr>
            <tr>
              <td>9 AWG</td>
              <td>2,90</td>
              <td>6,60</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>10 AWG</td>
              <td>2,59</td>
              <td>5,27</td>
              <td>120</td>
            </tr>
            <tr>
              <td>11 AWG</td>
              <td>2,30</td>
              <td>4,15</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>12 AWG</td>
              <td>2,05</td>
              <td>3,30</td>
              <td>70</td>
            </tr>
            <tr>
              <td>13 AWG</td>
              <td>1,83</td>
              <td>2,63</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>14 AWG</td>
              <td>1,63</td>
              <td>2,08</td>
              <td>45</td>
            </tr>
            <tr>
              <td>15 AWG</td>
              <td>1,45</td>
              <td>1,65</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>16 AWG</td>
              <td>1,29</td>
              <td>1,305</td>
              <td>30</td>
            </tr>
            <tr>
              <td>17 AWG</td>
              <td>1,14</td>
              <td>1,01</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>18 AWG</td>
              <td>1,02</td>
              <td>0,79</td>
              <td>20</td>
            </tr>
            <tr>
              <td>19 AWG</td>
              <td>0,91</td>
              <td>0,65</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>20 AWG</td>
              <td>0,81</td>
              <td>0,51</td>
              <td>12</td>
            </tr>
            <tr>
              <td>21 AWG</td>
              <td>0,72</td>
              <td>0,407</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>22 AWG</td>
              <td>0,64</td>
              <td>0,32</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>23 AWG</td>
              <td>0,57</td>
              <td>0,255</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>24 AWG</td>
              <td>0,51</td>
              <td>0,205</td>
              <td>10</td>
            </tr>
            <tr>
              <td>25 AWG</td>
              <td>0,455</td>
              <td>0,162</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>26 AWG</td>
              <td>0,40</td>
              <td>0,125</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>27 AWG</td>
              <td>0,36</td>
              <td>0,102</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>28 AWG</td>
              <td>0,32</td>
              <td>0,080</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>29 AWG</td>
              <td>0,287</td>
              <td>0,0646</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>30 AWG</td>
              <td>0,254</td>
              <td>0,0516</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>31 AWG</td>
              <td>0,226</td>
              <td>0,040</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>32 AWG</td>
              <td>0,203</td>
              <td>0,0324</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>33 AWG</td>
              <td>0,180</td>
              <td>0,0255</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>34 AWG</td>
              <td>0,160</td>
              <td>0,020</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>35 AWG</td>
              <td>0,142</td>
              <td>0,0158</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>36 AWG</td>
              <td>0,127</td>
              <td>0,0127</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>37 AWG</td>
              <td>0,114</td>
              <td>0,010</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>38 AWG</td>
              <td>0,101</td>
              <td>0,008</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>39 AWG</td>
              <td>0,089</td>
              <td>0,0062</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>40 AWG</td>
              <td>0,079</td>
              <td>0,0049</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>41 AWG</td>
              <td>0,071</td>
              <td>0,00395</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>42 AWG</td>
              <td>0,064</td>
              <td>0,00321</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>43 AWG</td>
              <td>0,056</td>
              <td>0,00246</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>44 AWG</td>
              <td>0,050</td>
              <td>0,00196</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>45 AWG</td>
              <td>0,045</td>
              <td>neuvedeno</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>46 AWG</td>
              <td>0,040</td>
              <td>neuvedeno</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>47 AWG</td>
              <td>0,036</td>
              <td>neuvedeno</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>48 AWG</td>
              <td>0,031</td>
              <td>neuvedeno</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>49 AWG</td>
              <td>0,028</td>
              <td>neuvedeno</td>
              <td>&mdash;</td>
            </tr>
            <tr>
              <td>50 AWG</td>
              <td>0,025</td>
              <td>neuvedeno</td>
              <td>&mdash;</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </>
  )
}

export default Kabely
