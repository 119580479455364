import { useMemo } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'

function useRouter() {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      path: location.pathname,
      // Merge params and parsed query string into single 'query' object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: 'react', sort: 'popular' }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, navigate so we have
      // access to extra React Router functionality if needed.
      location,
      navigate,
    }
  }, [navigate, location, params])
}

export default useRouter
