import axios from 'axios'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

const colorize = text => {
  const prefix = /^\u001b\[m/gm
  const grey = /\u001b\[33m([\w\s]+?)\u001b\[m/g
  const red = /\u001b\[31m([\w\s]+?)\u001b\[m/g
  const blue = /\u001b\[34m(\S+( = \S+)?)/g
  const green = /\u001b\[35m(.+)((\u001b\[m)|(?=<span))/g
  text = text.replaceAll(prefix, '')
  text = text.replaceAll(grey, '<span style="color: var(--bs-gray-500)">$1</span>')
  text = text.replaceAll(red, '<span className="text-danger">$1</span>')
  text = text.replaceAll(blue, '<span className="text-primary">$1</span>')
  text = text.replaceAll(green, '<span className="text-success">$1</span>')
  return text
}

const IPCalc = () => {
  const [error, setError] = useState(false)
  const [result, setResult] = useState('')

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.currentTarget

    if (!form.checkValidity()) {
      event.stopPropagation()
      setError(true)
      return
    }

    axios
      .post(process.env.REACT_APP_API_URL + '/ipcalc', {
        addr: form[0].value,
        mask: form[1].value
      })
      .then(result => {
        const data = result.data
        if (!data || data.status !== 'success') {
          setError(true)
          return
        }
        setResult(colorize(data.output))
      })
      .catch(error => {
        console.log(JSON.stringify(error))
        setError(true)
      })
  }

  return (
    <>
      <h1>IP Calculator</h1>
      <p>Calculator takes an IP address and netmask and calculates the resulting broadcast, network, and host range. Enter your netmask(s) in CIDR notation (<strong>/24</strong>) or dotted decimals (<strong>255.255.255.0</strong>). Inverse netmasks are recognized.</p>
      <Col lg>
        <Form noValidate onSubmit={handleSubmit} className="mb-sm-2 mb-md-2">
          <Form.Group className="mb-3" controlId="ipAddr">
            <Form.Label><strong>Address</strong> (Host or Network)</Form.Label>
            <Form.Control type="text" placeholder="Enter address" required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="mask">
            <Form.Label><strong>Netmask</strong> (i.e. 24)</Form.Label>
            <Form.Control type="text" placeholder="Enter netmask" required />
          </Form.Group>
          {error &&
            <Alert variant="danger" onClose={() => setError(false)} dismissible>
              Jejda, neco se pokazilo...
            </Alert>
          }
          <Button variant="primary" type="submit">
            Calculate
          </Button>
        </Form>
      </Col>
      <Col lg>
        <pre className="font-monospace border p-2 shadow-sm bg-light h-100">
          {parse(result)}
        </pre>
      </Col>
    </>
  )
}

export default IPCalc
