import Alert from 'react-bootstrap/Alert'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import { getErrorMsg } from '../utils'

const BulkFieldnotes = () => {
  const [error, setError] = useState('')
  const [ready, setReady] = useState(false)
  const [state, setState] = useState({
    numFinds: '',
    date: '',
    cacheList: '',
    logText: '',
  })

  const setValue = event => {
    const fieldId = event.target.id
    //const fieldVal = (fieldId === 'fnFile') ? event.target.files[0] : event.target.value
    setState({ ...state, [fieldId]: event.target.value })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setError('')
    setReady(false)

    const formData = new FormData()
    for (const key of Object.getOwnPropertyNames(state)) {
      formData.append(key, state[key]);
    }

    axios
      .post(process.env.REACT_APP_API_URL + '/bulkfn', formData)
      .then(result => {
        const data = result.data
        if (!data || data.status !== 200) {
          return Promise.reject(result)
        }
        setError('')
        setReady(true)
      })
      .catch(error => {
        setError(getErrorMsg(error))
        setReady(false)
        console.log(JSON.stringify(error))
      })
  }

  return (
    <>
      <h1>Fieldnotes generator</h1>
      <p>Generátor fieldnotes, který vytvoří fieldnotes pro zadaný den ze seznamu keší a doplní automaticky číslo nálezu <em>{"{"}NumFinds{"}"}</em> v textu logu</p>
      <Form onSubmit={handleSubmit}>
        <Row className="align-items-center mb-2">
          <Form.Group as={Col} controlId="numFinds">
            <Form.Label>Počet nálezů:</Form.Label>
            <Form.Control type="number" step="1" onChange={setValue} value={state.numFinds} />
          </Form.Group>
          <Form.Group as={Col} controlId="date">
            <Form.Label>Datum:</Form.Label>
            <Form.Control type="date" onChange={setValue} value={state.date} required />
          </Form.Group>
        </Row>
        <Row className="align-items-center mb-2">
          <Form.Group as={Col} controlId="cacheList">
            <Form.Label>Seznam keší oddělený bílýmy znaky:</Form.Label>
            <Form.Control as="textarea" onChange={setValue} value={state.cacheList} required rows={8} />
          </Form.Group>
          <Form.Group as={Col} controlId="logText">
            <Form.Label>Text logu:</Form.Label>
            <Form.Control as="textarea" onChange={setValue} value={state.logText} required rows={8} />
          </Form.Group>
        </Row>
        {error &&
          <Alert variant="danger" onClose={() => setError('')} dismissible>
            {error}
          </Alert>
        }
        <Button variant="primary" type="submit">
          Odeslat
        </Button>
        {' '}
        <Button href={process.env.REACT_APP_API_URL + '/fn'} variant="success" disabled={!ready}>
          Stáhnout
        </Button>
      </Form>
    </>
  )
}

export default BulkFieldnotes
