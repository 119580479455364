import React from 'react'
import { Alert, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap'
import { BrowserRouter, Link, Outlet, Route, Routes } from 'react-router-dom'

import useRouter from './hooks/use-router'

import Fieldnotes from './routes/Fieldnotes'
import BulkFieldnotes from './routes/BulkFieldnotes'
import Home from './routes/Home'
import IPCalc from './routes/IPCalc'
import Kabely from './routes/Kabely'
import YeastConverter from './routes/YeastConverter'

import './App.css';

const routes = [
  { path: '/drozdi', text: 'Přepočet droždí', element: <YeastConverter /> },
  { path: '/fn', text: 'Fieldnotes', element: <Fieldnotes /> },
  { path: '/bulkfn', text: 'Fieldnotes Generator', element: <BulkFieldnotes /> },
  { path: '/ipcalc', text: 'IP Calc', element: <IPCalc /> },
  { path: '/kabely', text: 'Průřezy kabelů', element: <Kabely /> },
]

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {routes.map((r, index) =>
            <Route key={index} path={r.path} element={r.element} />
          )}

          {/* <Route path="/hass-component-generator" element={<HCG />} /> */}
          {/* <Route path="dashboard" element={<Dashboard />} /> */}

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const checkConfig = () => {
  return process.env.REACT_APP_API_URL !== undefined
}

const Layout = () => {
  const router = useRouter()
  const configOk = checkConfig()

  const itemActive = tool => {
    return router.path.includes(tool)
  }

  return (
    <Container>
      <Row>
        <Navbar bg="dark" variant="dark">
          <Container>
            <Navbar.Brand as={Link} to="/">
              <img
                alt="Web Tools"
                src="/logo.svg"
                width="30"
                height="30"
                className="d-inline-block align-text-top me-1"
              />{' '}
            Web Tools
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title="Tool" id="basic-nav-dropdown" menuVariant="dark">
                  {routes.map((r, index) =>
                    <NavDropdown.Item key={index} as={Link} to={r.path} active={itemActive(r.path.slice(1))}>{r.text}</NavDropdown.Item>
                  )}
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
      <Row>
        {configOk ?
          <Outlet /> :
          <BadConfig />
        }
      </Row>
    </Container>
  )
}

const BadConfig = () => {
  return (
    <Alert variant="danger">
      Spatny konfig!
    </Alert>
  )
}

const NoMatch = () => {
  return (
    <Alert variant="danger">
      Pozadovana stranka nebyla nalezena.
    </Alert>
  )
}

export default App
