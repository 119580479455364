import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

const YeastConverter = () => {
  const [state, setState] = useState({
    amountFrom: '',
    precision: '2',
    unitFrom: '12',
    unitTo: '12',
    yeastFrom: '1',
    yeastTo: '1',
  })

  const setValue = e => {
    const fieldId = e.target.id
    const fieldVal = e.target.value
    setState({ ...state, [fieldId]: fieldVal })
  }

  let markup = <span className="fs-5 text-muted">Zadej množství</span>
  if (state.amountFrom !== '') {
    const result = convert(state)
    if (result) {
      markup = (
        <div className="text-center fs-5">
          <p><strong className="text-success">{spacify(result.from)}</strong> {getUnitAndYeast(state.unitFrom, state.yeastFrom)}</p>
          <p className="">se rovná</p>
          <p><strong className="text-success">{spacify(result.to)}</strong> {getUnitAndYeast(state.unitTo, state.yeastTo)}</p>
        </div>
      )
    } else {
      markup = <span className="fs-5 text-danger">Nastala chyba!</span>
    }
  }

  return (
    <>
      <h1>Přepočet droždí</h1>
      <p>Kalkulačka pro převod mezi třemi druhy droždí a jejich ekvivalentním množstvím v uncích, gramech, lžících a šálcích droždí.</p>
      <Col xs="4">
        <Form>
          <Row className="align-items-center mb-2">
            <Form.Group as={Col} controlId="amountFrom" xs={8}>
              <Form.Label>Množství:</Form.Label>
              <Form.Control type="text" onChange={setValue} value={state.amountFrom} />
            </Form.Group>
            <Form.Group as={Col} controlId="precision" xs={4}>
              <Form.Label>Přesnost:</Form.Label>
              <Form.Control type="number" step="1" onChange={setValue} value={state.precision} />
            </Form.Group>
          </Row>
          <strong>Převést z:</strong>
          <Row className="align-items-center mb-2">
            <Form.Group as={Col} controlId="yeastFrom" xs={8}>
              <Form.Label>Typ droždí:</Form.Label>
              <Form.Select onChange={setValue} value={state.yeastFrom}>
                <option value="1">Instantní</option>
                <option value="2">Čerstvé</option>
                <option value="3">Pivovarské</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="unitFrom" xs={4}>
              <Form.Label>Jednotky:</Form.Label>
              <Form.Select onChange={setValue} value={state.unitFrom}>
                <option value="12">g</option>
                <option value="0">tsp</option>
                <option value="1">tbsp</option>
                <option value="2">cup</option>
                <option value="13">oz</option>
              </Form.Select>
            </Form.Group>
          </Row>
          <strong>Převést do:</strong>
          <Row className="align-items-center">
            <Form.Group as={Col} controlId="yeastTo" xs={8}>
              <Form.Label>Typ droždí:</Form.Label>
              <Form.Select onChange={setValue} value={state.yeastTo}>
                <option value="1">Instantní</option>
                <option value="2">Čerstvé</option>
                <option value="3">Pivovarské</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="unitTo" xs={4}>
              <Form.Label>Jednotky:</Form.Label>
              <Form.Select onChange={setValue} value={state.unitTo}>
                <option value="12">g</option>
                <option value="0">tsp</option>
                <option value="1">tbsp</option>
                <option value="2">cup</option>
                <option value="13">oz</option>
              </Form.Select>
            </Form.Group>
          </Row>
        </Form>
      </Col>
      <Col lg>
        <pre className="font-monospace border p-2 shadow-sm bg-light h-100 position-relative">
          <div className="position-absolute top-50 start-50 translate-middle">
            {markup}
          </div>
        </pre>
      </Col>
    </>
  )
}

const factors = [
  24,
  8,
  0.5,
  16,
  16 / 3, // 5.333333333333333,
  1 / 3,
  48,
  16,
  1,
  160 / 3, // 53.33333333333333,
  160 / 9, // 17.77777777777778,
  10 / 9, // 1.111111111111111,
  68.03885544,
  2.4,
  50.39915217777778,
  16 / 9, // 1.777777777777778,
  136.07771088,
  4.8,
  151.1974565333333,
  16 / 3, // 5.333333333333333
]

// puvodni optiony:
// value="0">tsp - teaspoon of ACTIVE DRY yeast
// value="1">tbsp - tablespoon of ACTIVE DRY yeast
// value="2">cup-s of ACTIVE DRY yeast
// value="3">tsp - teaspoon of INSTANT yeast
// value="4">tbsp - tablespoon of INSTANT yeast
// value="5">cup-s of INSTANT yeast
// value="6">tsp - teaspoon of FRESH yeast
// value="7">tbsp - tablespoon of FRESH yeast
// value="8">cup-s of FRESH yeast
// value="9">tsp - teaspoon of BREWER's yeast
// value="10">tbsp - tablespoon of BREWER's yeast
// value="11">cup-s of BREWER's yeast powder
// value="12">g - (gram) of ACTIVE DRY yeast
// value="13">oz - (ounce) of ACTIVE DRY yeast
// value="14">g - (gram) of INSTANT yeast
// value="15">oz - (ounce) of INSTANT yeast
// value="16">g - (gram) of FRESH yeast
// value="17">oz - (ounce) of FRESH yeast
// value="18">g - (gram) of BREWER's yeast powder
// value="19">oz - (ounce) of BREWER yeast powder

// nove optiony:
// - tsp = 0, tbsp = 1, cup-s = 2, g = 12, oz = 13
// - active = 0, instant = 1, fresh = 2, brewer = 3
const getFactor = (unit, yeast) => {
  const u = parseInt(unit)
  const y = parseInt(yeast)

  if (u >= 12)
    return factors[y * 2 + u]
  else
    return factors[y * 3 + u]
}

const convert = (values) => {
  const { amountFrom, precision, unitFrom, unitTo, yeastFrom, yeastTo } = values

  // Written by Jeremy Larsen, Dec. 5, 2007

  // Get the value the user is typing (FROM)
  let numAmountFrom = 0

  // If user types a fraction
  if (/\/+/.test(amountFrom)) {
    // Check for whole number (ex. '4 1/2') - if exists, split string (ex. '4' and '1/2')
    let baseAmount, fracAmount
    if (/\d+\s+\d+/.test(amountFrom)) {
        baseAmount = amountFrom.replace(/^(\d+)\s.*/, '$1');
        fracAmount = amountFrom.replace(/^\d+\s+(.*)/, '$1');
        fracAmount = fracAmount.trim()
    } else {
        baseAmount = 0
        fracAmount = amountFrom
    }
    // Get both sides of the fraction (separated by '/')
    const fracAmounts = fracAmount.split('/', 2)
    if (fracAmounts[1] === undefined)
      numAmountFrom = 0
    else
      numAmountFrom = parseInt(baseAmount) + parseInt(fracAmounts[0]) / parseInt(fracAmounts[1])
  } else {
    numAmountFrom = parseFloat(amountFrom)
  }

  // Get FROM and TO units
  const factorFrom = getFactor(unitFrom, yeastFrom)
  const factorTo = getFactor(unitTo, yeastTo)

  // Calculate TO value
  let numAmountTo
  if (factorFrom === factorTo)
    numAmountTo = numAmountFrom
  else
    numAmountTo = (numAmountFrom / factorFrom) * factorTo

  let numPrecision = parseInt(precision.replace(/[^0-9]/, ''))
  if (isNaN(numPrecision)) {
    numPrecision = 2
  }

  {
    const pow = Math.pow(10, numPrecision)
    numAmountTo = Math.round(numAmountTo * pow) / pow
  }

  if (!isNaN(numAmountTo) && !isNaN(numAmountFrom)) {
    return {
      from: numAmountFrom,
      to: numAmountTo,
    }
  } else {
    return null
  }
}

const spacify = value => {
  let valueStr = value.toString()
  if (/\./.test(valueStr)) {
    const x = valueStr.split('.')
    while (/(\d+)(\d{3})/.test(x[0])) {
      x[0] = x[0].replace(/(\d+)(\d{3})/, '$1 $2');
    }
    while (/(\d{3})(\d+)/.test(x[1])) {
      x[1] = x[1].replace(/(\d{3})(\d+)/, '$1 $2');
    }
    valueStr = x[0] + '.' + x[1]
  } else {
    while (/(\d+)(\d{3})/.test(valueStr)) {
      valueStr = valueStr.replace(/(\d+)(\d{3})/, '$1 $2');
    }
  }
  return valueStr
}

// - tsp = 0, tbsp = 1, cup-s = 2, g = 12, oz = 13
// - active = 0, instant = 1, fresh = 2, brewer = 3

const getUnitAndYeast = (unit, yeast) => {
  let unitStr, yeastStr

  switch (unit) {
    case '0':
      unitStr = 'čajových lžiček'
      break;
    case '1':
      unitStr = 'lžic'
      break;
    case '2':
      unitStr = 'šálků'
      break;
    case '12':
      unitStr = 'gramů'
      break;
    case '13':
      unitStr = 'uncí'
      break;
    default:
      break;
  }

  switch (yeast) {
    case '0':
      yeastStr = 'aktivního droždí'
      break;
    case '1':
      yeastStr = 'instatního droždí'
      break;
    case '2':
      yeastStr = 'čerstvého droždí'
      break;
    case '3':
      yeastStr = 'pivovarského droždí'
      break;
    default:
      yeastStr = 'chyba'
      break;
  }

  return `${unitStr} ${yeastStr}`
}

export default YeastConverter
