export function getErrorMsg(error) {
  if (!error) {
    return 'Unknown error'
  } else if (error.data && error.data.message) {
    return error.data.message
  } else if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message
  }
  return 'Unknown error'
}
